import axios from 'axios'
import { Notification } from 'vue-notifyjs'
import i18n from '@/i18n'
import Problem from '@/util/problem'
import Vue from 'vue'

// default is no-op
let _logout = () => {};

export const config = {
  baseURL: location.hostname.includes('cloud-iam.com')
    ? /* in staging & production, the api is in the subdomain*/
      location.protocol + '//' + location.hostname.replace('app', 'api')
    : process.env.VUE_APP_BASE_URL,
  timeout: process.env.VUE_APP_API_BASE_TIMEOUT || 30000,
};

const clients = {
  // http client without default interceptors
  rawHttp: axios.create(config),

  // http client, aware of problems
  problemAwareHttp: axios.create(config),

  // http client with default interceptors on 400, 401, 403 etc...
  http: axios.create(config),
};

/**
 * @param {String} accessToken keycloak access token
 */
export function setBearer(accessToken) {
  Object.values(clients).forEach(
    (client) => (client.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`)
  );
}

/**
 * @param {Function} logout logout function
 */
export function setLogout(logout) {
  _logout = logout;
}

/**
 * Let external function listener to subscribe uniformly to every http clients success/error responses
 * @param {Function} f(errorResponse, successResponse)
 */
export function addResponseListener(f) {
  function safeCall(f, error, response) {
    try {
      // we want `f` call to be a side-effect *and* silent errors
      f(null, response);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  Object.values(clients).forEach((client) =>
    client.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        safeCall(f, null, response);
        return response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        safeCall(f, error);
        return Promise.reject(error);
      }
    )
  );
}

export const rawHttp = clients.rawHttp;

export const problemAwareHttp = clients.problemAwareHttp;

clients.problemAwareHttp.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.headers['content-type'] === 'application/problem+json') {
      return Promise.reject(
        new Problem(
          error.response.status,
          error.response.data.type,
          error.response.data.title,
          error.response.data.detail
        )
      );
    }
    const status = error?.response?.status ?? 0;
    if (status === 0) {
      return Promise.reject(
        new Problem(status, 'io-exception', 'Error while communicating with server', 'The API is currently unreachable')
      );
    } else {
      // show the user the reason why nothing moves
      Notification.notify({
        message: "Your session expired, please log in again",
        type: 'warning',
      });
      return Promise.reject(new Problem(status, 'internal', 'Oops', 'Unidentified'));
    }
  }
);

function handleTokenRenewal(config) {
  return new Promise((resolve, reject) => {
    renewTokenIfExpired()
      .then(() => {
        Vue.$log.debug('End of renewTokenIfExpired');
        config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`;
        resolve(config);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

clients.http.interceptors.request.use(
  function (config) {
    return handleTokenRenewal(config);
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

clients.problemAwareHttp.interceptors.request.use(
  function (config) {
    return handleTokenRenewal(config);
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

async function renewTokenIfExpired() {
  // Do something before request is sent
  Vue.$log.debug(
    'Token will expired in less than ' +
    Math.round(
      Vue.prototype.$keycloak.tokenParsed.exp + Vue.prototype.$keycloak.timeSkew - new Date().getTime() / 1000
    ) +
    ' seconds'
  );

  Vue.$log.debug(
    'Refresh Token will expired in less than ' +
    Math.round(
      Vue.prototype.$keycloak.refreshTokenParsed.exp + Vue.prototype.$keycloak.timeSkew - new Date().getTime() / 1000
    ) +
    ' seconds'
  );

  if (Vue.prototype.$keycloak.isTokenExpired(30)) {
    Vue.$log.debug('Token will expired in less than 30s');
    await Vue.prototype.$keycloak.updateToken(30).then((refreshed) => {
      if (refreshed) {
        Vue.$log.info('Token was successfully refreshed');
        setBearer(Vue.prototype.$keycloak.token);
      } else {
        Vue.$log.warn('Token is still valid');
      }
    })
      .catch(() => {
        Vue.$log.error('Failed to refresh the token, or the session has expired');
      });
  } else {
    Vue.$log.debug('Token is not expired');
  }
}

clients.http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          badRequest(error.response.data.title);
          break;
        case 401:
          unauthorized();
          break;
        case 403:
          forbidden();
          break;
        case 404:
        case 502:
          notFound();
          break;
        case 500:
          apiError(error.response.data.title);
          break;
        default:
          unknown();
      }
      return Promise.reject(error);
    }
  }
);

function badRequest(message) {
  Notification.notify({
    message: message,
    type: 'warning',
  });
}

function unauthorized() {
  Notification.notify({
    message: i18n.t('notifications.unauthorized'),
    type: 'warning',
  });
  _logout();
}

function forbidden() {
  Notification.notify({
    message: i18n.t('notifications.forbidden'),
    type: 'warning',
  });
}

function notFound() {
  Notification.notify({
    message: i18n.t('notifications.notFound'),
    type: 'danger',
  });
}

function apiError(message) {
  Notification.notify({
    message: message,
    type: 'danger',
  });
}

function unknown() {
  Notification.notify({
    message: i18n.t('notifications.unknown'),
    type: 'danger',
  });
}

export const http = clients.http;

export default clients.http;
