<template>
  <div class="flex flex-row items-center">
    <div v-if="hasSlot('warningText')" class="group flex relative">
      <span v-if="showWarning == true" class="show-warning">
        <slot name="warningText"></slot>
      </span>
      <div @mouseover="displayWarning()">
        <ciam-icon name="fa-exclamation-triangle" class="text-yellow-500 mr-1 w-5 h-5"/>
      </div>
    </div>

    <div class="relative" v-if="hasSlot('displayIcon')">
      <div class="absolute left-0 top-1/2 transform -translate-y-1/2 pl-2">
        <ciam-icon :name="icon" class="text-gray-300 w-5 h-5" />
      </div>
      <input v-bind="{ ...$props, ...$attrs }" class="ciam-input pl-10" @input="_emit($event.target.value)" />
    </div>
    <input v-else v-bind="{ ...$props, ...$attrs }" class="ciam-input" @input="_emit($event.target.value)" />
    <p class="ciam-input--help-text" v-if="hasSlot('helpText')">
      <slot name="helpText"></slot>
    </p>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';

export default {
  name: 'ciam-input',
  inheritAttrs: false,
  props: {
    helpText: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
      // default: 'fa-search',
    },
    debounceTimeout: {
      type: Number,
      default: 2000,
    },
  },
  components: {},
  data() {
    return {
      showWarning: false
    }
  },
  methods: {
    hasSlot(name = 'default') {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
    displayWarning() {
      this.showWarning = true;
    setTimeout(() => {
      this.showWarning = false;
    }, 2000);
    },
    _emit(value) {
      this.$emit('input', value);
      this.handleInputDebounced(value);
    },
  },
  mounted() {},
  beforeDestroy() {},
  computed: {
    handleInputDebounced() {
      return debounce((value) => this.$emit('change-debounced', value), this.debounceTimeout);
    },
  },
};
</script>

<style lang="scss" scoped>
.show-warning {
  position: absolute !important;
  top: -400% !important;

}

.ciam-input {
  @apply block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md;
}

.ciam-input--help-text {
  @apply mt-2 text-sm text-gray-500;
}

.ciam-input:invalid {
  @apply border-red-600;
}

.ciam-input:disabled {
  @apply bg-gray-100 select-none cursor-not-allowed;
}

.show-warning {
  @apply bg-yellow-300 px-1  text-yellow-800 rounded-md absolute left-1/2 -translate-x-1/2 translate-y-full m-4 p-2 w-56 mx-auto;
}
</style>
