<template>
  <font-awesome-icon v-bind="{ ...$props, ...$attrs }" :icon="'fa-solid ' + name" />
</template>

<script>
// Search for icons here: https://fontawesome.com/search?m=free&s=solid
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  name: 'ciam-icon',
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  computed: {},
};
</script>

<style lang="scss" scoped></style>
